import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import DiscoContainerButton, {
  DiscoContainerButtonProps,
} from "@disco-ui/button/DiscoContainerButton"
import DiscoText, { DiscoTextSkeleton } from "@disco-ui/text/DiscoText"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

export interface DiscoCardProps
  extends TestIDProps,
    Pick<DiscoContainerButtonProps, "onClick" | "disabled" | "tooltip" | "tooltipProps"> {
  classes?: {
    card?: string
    details?: string
    buttons?: string
  }
  icon?: React.ReactNode
  title: string | React.ReactElement
  description?: string | React.ReactElement
  buttons?: React.ReactElement | null
  variant?: "compact" | "default"
  inCarousel?: boolean
}

function DiscoCard(props: DiscoCardProps) {
  const {
    testid = "DiscoCard",
    classes: customClasses = {},
    icon,
    title,
    description,
    buttons,
    variant = "default",
    onClick,
    disabled,
    tooltip,
    tooltipProps,
    inCarousel,
  } = props

  const classes = useStyles({ inCarousel, variant, hasOnClick: Boolean(onClick) })

  if (onClick)
    return (
      <DiscoContainerButton
        testid={`${testid}.root`}
        className={classNames(classes.card, customClasses?.card)}
        onClick={onClick}
        disabled={disabled}
        tooltip={tooltip}
        tooltipProps={tooltipProps}
      >
        {renderContent()}
      </DiscoContainerButton>
    )

  return (
    <div
      className={classNames(classes.card, customClasses?.card)}
      data-testid={`${testid}.root`}
    >
      {renderContent()}
    </div>
  )

  function renderContent() {
    return (
      <>
        <div className={classNames(classes.details, customClasses.details)}>
          <div className={classes.header}>
            {/* Cover */}
            <div data-testid={`${testid}.icon`}>{icon}</div>
            {/* Name */}
            <DiscoText testid={`${testid}.title`} variant={"body-md-600"}>
              {title}
            </DiscoText>
          </div>
          {/* Description */}
          {description && (
            <DiscoText
              variant={variant === "default" ? "body-sm" : "body-xs"}
              color={"text.secondary"}
              testid={`${testid}.description`}
            >
              {description}
            </DiscoText>
          )}
        </div>
        {/* Buttons */}
        {buttons && (
          <div className={classes.buttons} data-testid={`${testid}.buttons`}>
            {buttons}
          </div>
        )}
      </>
    )
  }
}

export function DiscoCardSkeleton(
  props: Pick<DiscoCardProps, "classes" | "testid" | "buttons" | "variant" | "inCarousel">
) {
  const {
    classes: customClasses = {},
    testid = "DiscoCardSkeleton",
    buttons,
    variant = "default",
    inCarousel,
  } = props

  const classes = useStyles({ variant, hasOnClick: false, inCarousel })

  return (
    <div
      style={{ width: "100%" }}
      className={classNames(classes.card, customClasses?.card)}
      data-testid={`${testid}.root`}
    >
      <div className={classes.details}>
        <div className={classes.header}>
          <Skeleton variant={"circle"} width={"40px"} height={"40px"} />
          <DiscoTextSkeleton variant={"body-md-600"} width={"80%"} marginTop={1.5} />
        </div>
        <DiscoTextSkeleton
          variant={variant === "default" ? "body-sm" : "body-xs"}
          width={"100%"}
          marginTop={0.5}
          marginBottom={variant === "default" ? 2 : 0}
        />
      </div>
      <div
        className={classNames(classes.buttons, customClasses?.buttons)}
        data-testid={`${testid}.buttons`}
      >
        {buttons}
      </div>
    </div>
  )
}

type StyleProps = {
  variant: DiscoCardProps["variant"]
  inCarousel: DiscoCardProps["inCarousel"]
  hasOnClick: boolean
}

const useStyles = makeUseStyles((theme) => ({
  card: ({ variant, hasOnClick, inCarousel }: StyleProps) => ({
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    textAlign: "left",

    borderRadius: theme.measure.borderRadius.big,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    transition: "box-shadow 0.2s ease-in-out",
    boxShadow: theme.palette.groovyDepths.insideCard,

    ...styleIf(inCarousel, {
      // set the margin so that the boxShadow doesn't get cut off
      margin: theme.spacing(0.5),
    }),

    ...styleIf(variant === "default", {
      maxWidth: "100%",
      height: "100%",
      minHeight: "175px",
    }),

    ...styleIf(variant === "compact", {
      padding: theme.spacing(2),
    }),

    ...styleIf(hasOnClick, {
      "&:hover": {
        boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
      },
    }),
  }),
  header: ({ variant }: StyleProps) => ({
    display: "flex",
    gap: theme.spacing(1.5),
    alignItems: "center",

    ...styleIf(variant === "default", {
      flexDirection: "column",
      alignItems: "start",
    }),
  }),
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "end",
  },
}))

export default DiscoCard
