import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GroovyColor } from "@assets/style/appMuiTheme"
import mergeClasses from "@assets/style/util/mergeClasses"
import styleIf from "@assets/style/util/styleIf"
import { DiscoText, DiscoTooltip, DiscoTooltipProps } from "@disco-ui"
import DropdownIcon from "@disco-ui/dropdown/DropdownIcon"
import {
  FilterSelectOptionsConfig,
  useFilterSelectOptions,
} from "@disco-ui/select/selectUtils"
import DiscoTextField, { DiscoTextFieldProps } from "@disco-ui/text-field/DiscoTextField"
import { MenuItem, Select, SelectProps } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
  Skeleton,
} from "@material-ui/lab"
import classNames from "classnames"

export interface SelectOption<T = any, C = any> {
  value: T
  title: string
  subtitle?: string
  searchable?: (string | null | undefined)[]
  context?: C
  disabled?: boolean
  disabledTooltipContent?: DiscoTooltipProps["content"]
  testid?: string
}

// TODO: mess with the generic types in Autocomplete
// so that setting disableClearable, multiple, or freeSolo
// props modify the types of other props to reflect value/onChange types.
export type DiscoSelectProps<T, P> = {
  testid?: string
  value: T | null | undefined
  onChange: (v: T | null) => void | never
  options: P[]
  disableClearable?: boolean
  placeholder?: string
  variant?: "default" | "inline" | "compact"
  // Color is only supported in non-autocomplete selects
  color?: GroovyColor
  classes?: Partial<
    ClassNameMap<
      "popup" | "popper" | "listbox" | "input" | "root" | "inputRoot" | "menuItem"
    >
  >
  className?: string
  // Automatically select the first option if this is set to true
  autoSelect?: boolean
  autoComplete?: boolean
  autoFocusInput?: boolean
  // P is any object that extends SelectOption<T>
  renderOption?: (option: P, state?: AutocompleteRenderOptionState) => React.ReactNode
  renderValue?: (option: P) => React.ReactNode
  groupBy?: (option: P) => string
  renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode
  noOptionsText?: string
  disabled?: boolean
  filterOptions?: FilterSelectOptionsConfig
  textFieldInputProps?: DiscoTextFieldProps["InputProps"]
  customSelectProps?: Partial<CustomSelectProps<SelectProps, P>>
} & Pick<SelectProps, "autoWidth">

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const DiscoSelect = <T extends any, P extends SelectOption<T>>(
  props: DiscoSelectProps<T, P>
) => {
  const {
    value,
    onChange,
    options,
    disableClearable,
    testid,
    placeholder,
    renderOption,
    renderValue,
    noOptionsText,
    disabled,
    autoSelect = false,
    autoComplete = true,
    autoFocusInput,
    filterOptions,
    className,
    customSelectProps,
    variant,
    color,
    ...rest
  } = props

  const selectedOption = options.find((o) => o.value === value)

  const filterSelectOptions = useFilterSelectOptions(filterOptions)

  if (autoComplete)
    return (
      <CustomAutocomplete
        testid={testid}
        value={selectedOption ?? null}
        options={options}
        onChange={(_, option) => onChange(option ? option.value : null)}
        getOptionLabel={(o) => o.title}
        disableClearable={disableClearable}
        multiple={false}
        freeSolo={false}
        renderOption={renderOption}
        noOptionsText={noOptionsText}
        disabled={disabled}
        defaultValue={autoSelect && options.length > 0 ? options[0] : undefined}
        filterOptions={filterSelectOptions as () => P[]}
        placeholder={placeholder}
        autoFocusInput={autoFocusInput}
        // compact variant not supported in CustomAutocomplete
        variant={variant === "compact" ? "default" : variant}
        {...rest}
      />
    )

  return (
    <CustomSelect
      testid={testid}
      value={selectedOption?.value}
      placeholder={placeholder}
      options={options}
      onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
        onChange(event.target.value as T)
      }
      classes={{ root: rest.classes?.root }}
      disabled={disabled}
      renderOption={renderOption}
      renderValue={renderValue}
      className={className}
      {...customSelectProps}
      // Inline variant not supported in CustomSelect
      variant={variant === "inline" ? "default" : variant}
      color={color}
    />
  )
}

export default DiscoSelect

/**
 * Automcomplete style select (type-to-search)
 */
type CustomAutocompleteProps<
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  TValue extends any,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<AutocompleteProps<TValue, false, DisableClearable, FreeSolo>, "renderInput"> & {
  variant?: "default" | "inline"
  testid?: string
  textFieldInputProps?: DiscoTextFieldProps["InputProps"]
  autoFocusInput?: boolean
}

const CustomAutocomplete = <
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  TValue extends any,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: CustomAutocompleteProps<TValue, DisableClearable, FreeSolo>
) => {
  const {
    testid,
    placeholder,
    variant = "default",
    classes: propsClasses,
    textFieldInputProps,
    autoFocusInput,
    ...rest
  } = props
  const classes = useStyles({ variant })

  return (
    <Autocomplete
      {...rest}
      getOptionDisabled={(option) => !!(option as SelectOption).disabled}
      classes={{ ...classes, ...propsClasses }}
      data-testid={testid}
      renderInput={({ InputProps, ...params }) => (
        <DiscoTextField
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocusInput}
          placeholder={placeholder}
          InputProps={{ ...InputProps, ...textFieldInputProps }}
          {...params}
        />
      )}
      popupIcon={<DropdownIcon data-testid={`${testid}.popup-icon`} />}
    />
  )
}

type StyleProps = {
  variant: "default" | "inline"
}

const useStyles = makeUseStyles((theme) => ({
  inputRoot: ({ variant }: StyleProps) => ({
    ...theme.typography["body-sm"],
    padding: "0 !important",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[500]
        : theme.palette.groovy.neutral[100],
    flexWrap: "nowrap",
    height: "40px",
    borderRadius: theme.measure.borderRadius.big,
    ...styleIf(variant === "inline", {
      border: "none",
      backgroundColor: theme.palette.background.paper,
    }),
    "&.Mui-focused": {
      boxShadow:
        theme.palette.type === "dark"
          ? `0 0 0 1.5px ${theme.palette.primary.main}`
          : `0 0 0 1.5px ${theme.palette.primary.main}, 0 0 0 4.5px ${theme.palette.primary.light}`,
    },
    "&:hover:not(.Mui-focused)": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[400]
          : theme.palette.groovy.neutral[200],
      boxShadow: `0 0 0 1.5px ${theme.palette.groovy.grey[400]}`,
    },
  }),
  input: {
    cursor: "pointer",
    padding: `${theme.spacing(1.5)}px !important`,
  },
  listbox: {
    padding: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
  option: {
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(1.5),
    "&:not(:first-child)": {
      marginTop: theme.spacing(0.5),
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[100],
    },
  },
  endAdornment: {
    "& svg": {
      color: theme.palette.text.primary,
    },
  },
}))

export function DiscoSelectSkeleton() {
  return <Skeleton variant={"rect"} height={40} />
}

/**
 * Basic/non-autocomplete style select (no type-to-search)
 */
type CustomSelectProps<SelectProps, P> = Omit<
  SelectProps,
  "renderValue" | "variant" | "color"
> & {
  testid?: string
  options: P[]
  renderOption?: (option: P) => React.ReactNode
  renderValue?: (option: P) => React.ReactNode
  variant?: "default" | "compact"
  color?: GroovyColor
  menuItemClassName?: string
}

const CustomSelect = <P extends SelectOption>(
  props: CustomSelectProps<SelectProps, P>
) => {
  const {
    testid,
    placeholder,
    options,
    classes: propsClasses,
    defaultValue,
    renderOption,
    renderValue,
    value,
    className,
    menuItemClassName,
    variant = "default",
    color,
    ...rest
  } = props
  const classes = useStylesSelect({ variant, color })

  return (
    <Select
      {...rest}
      MenuProps={{
        ...rest.MenuProps,
        classes: mergeClasses({ paper: classes.paper }, rest.MenuProps?.classes),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
          ...rest.MenuProps?.anchorOrigin,
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
          ...rest.MenuProps?.transformOrigin,
        },
        getContentAnchorEl: null,
        // Disabled MUI animation
        transitionDuration: 0,
      }}
      IconComponent={(iconProps) => (
        <DropdownIcon
          {...iconProps}
          className={classNames(iconProps.className, classes.icon)}
        />
      )}
      disableUnderline
      displayEmpty
      value={value ?? ""}
      defaultValue={defaultValue}
      className={classNames(classes.inputBase, className)}
      classes={mergeClasses({ root: classes.root }, propsClasses)}
      renderValue={(selected) => {
        const val = selected as any
        const selectedOption = options.find(
          (o) => o.value === val || o.value === val.value
        )
        if (!selectedOption) return placeholder
        return renderValue ? renderValue(selectedOption) : selectedOption.title
      }}
      data-testid={testid}
    >
      {options.map((option, idx) => {
        return (
          <MenuItem
            key={option.value}
            data-testid={`${testid}-answer-${idx}`}
            className={classNames(classes.menuItem, menuItemClassName)}
            value={option.value}
            disabled={option.disabled}
          >
            <span
              role={"button"}
              tabIndex={0}
              onClick={option.disabled ? (e) => e.stopPropagation() : undefined}
              onKeyPress={option.disabled ? (e) => e.stopPropagation() : undefined}
              style={{ pointerEvents: "auto" }}
            >
              <DiscoTooltip
                content={option.disabledTooltipContent}
                disabled={!option.disabledTooltipContent}
              >
                <span>
                  {renderOption ? (
                    renderOption(option)
                  ) : (
                    <div>
                      <DiscoText variant={option.subtitle ? "body-sm-600" : "body-sm"}>
                        {option.title}
                      </DiscoText>
                      {option.subtitle && (
                        <DiscoText
                          variant={"body-sm"}
                          color={"text.secondary"}
                          marginTop={0.5}
                        >
                          {option.subtitle}
                        </DiscoText>
                      )}
                    </div>
                  )}
                </span>
              </DiscoTooltip>
            </span>
          </MenuItem>
        )
      })}
    </Select>
  )
}

type SelectStyleProps = {
  variant: "default" | "compact"
  color?: GroovyColor
}

const useStylesSelect = makeUseStyles((theme) => ({
  root: ({ color, variant }: SelectStyleProps) => ({
    "&, &:focus": {
      backgroundColor: color
        ? theme.palette.groovy[color][100]
        : theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[500]
        : theme.palette.groovy.neutral[100],
    },
    color: color ? theme.palette.groovy[color][600] : theme.palette.text.primary,
    padding: theme.spacing(1.25, 1.5),
    paddingRight: `${theme.spacing(4.5)}px !important`,
    borderRadius: theme.measure.borderRadius.big,
    borderBottom: "none",
    ...styleIf(variant === "compact", {
      padding: theme.spacing(0.5, 1.5),
      ...theme.typography["body-sm"],
      ...theme.typography.modifiers.fontWeight[500],
    }),
    "&.Mui-disabled": {
      color: color
        ? undefined
        : theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[300]
        : theme.palette.groovy.neutral[400],
      opacity: 0.6,
    },
    "&:focus": {
      borderRadius: theme.measure.borderRadius.big,
      borderBottom: "none",
    },
    "& option": {
      fontFamily: theme.typography.fontFamily,
    },
  }),
  paper: {
    borderRadius: theme.measure.borderRadius.medium,
    boxShadow: theme.shadows[1],
    width: "min-content",
  },
  menuItem: {
    whiteSpace: "normal",
  },
  inputBase: {
    "label + &": {
      marginTop: 0,
    },
  },
  icon: ({ color, variant }: SelectStyleProps) => ({
    color: color ? theme.palette.groovy[color][600] : theme.palette.text.primary,
    height: variant === "compact" ? 16 : 20,
    width: variant === "compact" ? 16 : 20,
    top: `calc(50% - ${variant === "compact" ? 8 : 10}px)`,
    right: theme.spacing(variant === "compact" ? 1.5 : 1),
  }),
}))
