import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import ChevronIcon from "@/core/ui/iconsax/linear/arrow-right-1.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { useDashboardContext } from "@/dashboard/context/DashboardContext"
import useIsWebView from "@/product/util/hook/useIsWebView"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import Swiper, { SwiperProps } from "@components/carousel/Swiper"
import SwiperSlide from "@components/carousel/SwiperSlide"
import { DiscoIconButtonSkeleton, DiscoSection, DiscoTextSkeleton } from "@disco-ui"
import { DiscoCarouselItemSkeleton } from "@disco-ui/carousel/DiscoCarouselItem"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import DiscoText from "@disco-ui/text/DiscoText"
import { IconButton, useMediaQuery, useTheme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { SkeletonProps } from "@material-ui/lab/Skeleton"
import { range } from "@utils/array/arrayUtils"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React, { Fragment, useMemo, useState } from "react"
import { useRouteMatch } from "react-router-dom"
import { Swiper as SwiperType } from "swiper"

export type DiscoCarouselProps<T> = TestIDProps & {
  classes?: Partial<ClassNameMap<"carousel" | "actions" | "slide">>
  /** The connection data */
  data: T[]
  /**
   * Number of visible slides in the carousel
   * prefer to pass `breakpoints` as this will set a fixed number of slides for all screen sizes
   */
  slidesPerView?: SwiperProps["config"]["slidesPerView"]
  centeredSlides?: boolean
  /**
   * Specify the number of slides that should be visible at designated breakpoints
   * @key {0} = xs+
   * @key {600} = sm+
   * @key {1280} = lg+
   * these are logical breakpoints that work with DiscoPage/Content
   */
  breakpoints?: NonNullable<SwiperProps["config"]>["breakpoints"]
  title?: string | React.ReactNode
  subTitle?: string | React.ReactNode
  /** Optional empty state, returns `null` by default if empty */
  emptyState?: React.ReactNode
  className?: string
  /** Additional CTA's beside the carousel navigation buttons */
  moreActions?: React.ReactNode | null
  /** Optional alternative starting index
   * @example
   * items = ["cat", "cat", "dog"]
   * condition = "dog"
   * @returns {Number} startingIndex = 2
   * Since we want to find the index which "dog" first appears in our array
   */
  startingIndex?: number
  totalCount: number
  refetch?: {
    isLoading: boolean
    hasNext: boolean
    hasPrevious: boolean
    loadMore: VoidFunction
  }
  /** Render method for each item */
  item: (item: T, index: number) => React.ReactElement<{ key: string }>
  itemSkeleton?: React.ReactElement
  disableNavButtons?: boolean
  padding?: number
}

const DiscoCarousel = <T extends Record<string, any>>({
  title,
  subTitle,
  emptyState = null,
  className,
  moreActions,
  testid = "DiscoCarousel",

  startingIndex,
  slidesPerView,
  breakpoints,
  centeredSlides = true,

  totalCount,
  refetch,
  data,
  item: _renderItem,
  itemSkeleton,
  classes: customClasses,
  disableNavButtons = false,
  padding = 2,
}: DiscoCarouselProps<T>) => {
  const [carouselState, setCarouselState] = useState<SwiperType | null>(null)
  const [nextEl, setNextEl] = useState<HTMLDivElement | null>(null)
  const [prevEl, setPrevEl] = useState<HTMLDivElement | null>(null)
  const onShowHoverClasses = useShowOnHoverStyles()
  const activeOrganization = useActiveOrganization()
  const { isCommunityWelcome } = useDashboardContext() || {}
  const theme = useTheme()
  const isMobile = useIsMobile()
  const isTablet = useMediaQuery(theme.breakpoints.up("sm"))
  const isWebView = useIsWebView()
  const match = useRouteMatch()
  const onForYouPage = match.path === ROUTE_NAMES.COMMUNITY.HOME.FOR_YOU
  const isForYouPage =
    onForYouPage ||
    (match.path === ROUTE_NAMES.COMMUNITY.HOME.ROOT &&
      activeOrganization?.forYouDashboard?.published)

  const config = useMemo(() => {
    return {
      navigation: { nextEl, prevEl },
      initialSlide: startingIndex,
      slidesPerView:
        isWebView && isTablet ? 3.1 : isMobile || isWebView ? 1.4 : slidesPerView,
      breakpoints,
      setParentCarouselState: setCarouselState,
      totalCount,
      currentSliceSize: data.length,
      centeredSlides,
    }
  }, [
    breakpoints,
    nextEl,
    prevEl,
    slidesPerView,
    centeredSlides,
    startingIndex,
    totalCount,
    data.length,
    isWebView,
    isMobile,
    isTablet,
  ])

  const classes = useStyles({
    isBeginning: carouselState?.isBeginning || false,
    isEnd: carouselState?.isEnd || false,
    isCommunityWelcome,
  })
  const sectionPadding = isForYouPage
    ? {
        padding: 0,
      }
    : {
        paddingTop: padding,
        paddingLeft: padding,
        paddingRight: padding,
        // bottom padding is 20px - 16px bottom margin on items for dropshadow = 4px
        paddingBottom: 0.5,
      }

  // fix issue where buttons are not disabled when all items are visible within the carousel track
  const navButtonsDisabled = carouselState?.virtual?.slides?.length
    ? carouselState.virtual.slides.length <= carouselState?.width
    : false
  const isNextButtonDisabled =
    (disableNavButtons || navButtonsDisabled) ?? carouselState?.isEnd
  const isPrevButtonDisabled =
    (disableNavButtons || navButtonsDisabled) ?? carouselState?.isBeginning

  const shouldShowHeader = title || moreActions

  return (
    <DiscoSection
      data-testid={testid}
      className={classNames(
        classes.paperContainer,
        onShowHoverClasses.hoverable,
        className
      )}
      {...sectionPadding}
    >
      {/* title and page indicator will overflow if tittle is too long */}
      {shouldShowHeader && (
        <div className={classes.header}>
          <div className={classes.titleContainer}>
            {/* Title */}
            {title &&
              (typeof title === "string" ? (
                <DiscoText variant={"heading-xs-700"} testid={`${testid}.title`}>
                  {title}
                </DiscoText>
              ) : (
                title
              ))}
            {subTitle &&
              (typeof subTitle === "string" ? (
                <DiscoText
                  variant={"body-xs"}
                  truncateText={1}
                  component={"span"}
                  testid={`${testid}.subTitle`}
                >
                  {subTitle}
                </DiscoText>
              ) : (
                subTitle
              ))}
          </div>
          <div
            className={classNames(
              classes.actions,
              onShowHoverClasses.showable,
              customClasses?.actions
            )}
          >
            {/* Actions */}
            {moreActions}
          </div>
        </div>
      )}

      {/* Items */}
      {data.length ? (
        <>
          <Swiper
            config={config}
            className={classNames(classes.carousel, customClasses?.carousel)}
          >
            {data.map((item, i) => (
              <SwiperSlide key={item.id} index={i} className={customClasses?.slide}>
                {_renderItem(item, i)}
              </SwiperSlide>
            ))}

            {data.length !== totalCount && refetch?.hasNext && (
              <SwiperSlide>
                <DiscoScrolledIntoView
                  className={classes.loadingContainer}
                  isLoading={refetch.isLoading}
                  onScrolledIntoView={refetch.loadMore}
                  skeleton={itemSkeleton ?? <DiscoCarouselItemSkeleton />}
                />
              </SwiperSlide>
            )}
          </Swiper>

          {/* Back */}
          <div ref={(el) => setPrevEl(el)} className={classes.gradientPreviewPrev}>
            <IconButton
              className={classNames(classes.navButton, classes.prevNavButton)}
              data-testid={`${testid}.previous-button`}
              disabled={isPrevButtonDisabled}
            >
              <ChevronIcon className={classes.leftChevron} width={16} height={16} />
            </IconButton>
          </div>

          {/* Forward */}
          <div ref={(el) => setNextEl(el)} className={classes.gradientPreviewNext}>
            <IconButton
              className={classNames(classes.navButton, classes.nextNavButton)}
              data-testid={`${testid}.next-button`}
              disabled={isNextButtonDisabled}
            >
              <ChevronIcon width={16} height={16} />
            </IconButton>
          </div>
        </>
      ) : (
        emptyState
      )}
    </DiscoSection>
  )
}

interface StyleProps {
  isBeginning?: boolean
  isEnd?: boolean
  isCommunityWelcome?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  paperContainer: {
    position: "relative",
    background: "none",
  },
  carousel: ({ isBeginning, isEnd, isCommunityWelcome }: StyleProps) => ({
    // use a negative margin to extend the carousel to the edges of the DiscoSection
    // when only 1 item is visible, we want to respect the padding around the DiscoSection
    marginLeft: theme.spacing(-1),
    backgroundColor: isCommunityWelcome
      ? theme.palette.background.paper
      : theme.palette.background.default,
    borderRadius: theme.measure.borderRadius.big,

    "&::before": {
      height: "100%",
      position: "absolute",
      width: "30px",
      zIndex: 2,
      content: `""`,
      background: "transparent",
      maskImage: "none",
      pointerEvents: "none",
      ...styleIf(!isBeginning, {
        maskImage: "linear-gradient(to left, transparent 0%, black 100%)",
        background: "inherit",
      }),
    },
    "&::after": {
      height: "100%",
      position: "absolute",
      background: "transparent",
      width: "30px",
      zIndex: 2,
      content: `""`,
      right: 0,
      top: 0,
      maskImage: "none",
      pointerEvents: "none",
      ...styleIf(!isEnd, {
        maskImage: "linear-gradient(to right, transparent 0%, black 100%)",
        background: "inherit",
      }),
    },
  }),
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    flexWrap: "wrap",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  actions: {
    display: "flex",
    gap: theme.spacing(0.25),
    alignItems: "center",
  },
  leftChevron: {
    transform: "rotate(180deg)",
  },
  navButton: {
    position: "absolute",
    zIndex: 2,
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(1),
    alignSelf: "center",

    transition: "background-color 0.4s ease",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.boxShadow,

    "&.swiper-button-disabled, &.swiper-button-lock, &.Mui-disabled": {
      display: "none",
    },

    "&:hover": {
      background: theme.palette.groovy.neutral[100],
    },
  },
  prevNavButton: {
    left: -5,

    [theme.breakpoints.down("xs")]: {
      left: -4,
    },
  },
  nextNavButton: {
    right: -5,

    [theme.breakpoints.down("xs")]: {
      right: -4,
    },
  },
  loadingContainer: {
    display: "flex",
  },
  gradientPreviewPrev: ({ isBeginning }: StyleProps) => ({
    width: "60px",
    height: "80%",
    backgroundColor: "transparent",
    position: "absolute",
    zIndex: 2,
    top: 40,
    cursor: "pointer",
    display: "flex",
    left: -2,

    ...styleIf(isBeginning, {
      display: "none",
    }),
  }),
  gradientPreviewNext: ({ isEnd }: StyleProps) => ({
    width: "60px",
    height: "80%",
    backgroundColor: "transparent",
    position: "absolute",
    zIndex: 2,
    top: 40,
    cursor: "pointer",
    display: "flex",
    right: -2,

    ...styleIf(isEnd, {
      display: "none",
    }),
  }),
}))

type DiscoCarouselSkeletonProps = Omit<
  DiscoCarouselProps<any>,
  "data" | "Item" | "totalCount" | "refetch" | "title" | "item"
> &
  SkeletonProps & {
    item: React.ReactElement
    showTitle?: boolean
    showHeader?: boolean
  }

export const DiscoCarouselSkeleton: React.FC<DiscoCarouselSkeletonProps> = ({
  item,
  showTitle = false,
  showHeader = true,
  title,
  ...props
}) => {
  const theme = useTheme()
  const isMobile = useIsMobile()

  // handle responsive slidesPerView
  const slidesPerView = isMobile
    ? 1
    : typeof props.slidesPerView === "number"
    ? props.slidesPerView
    : 3

  const classes = useStyles({
    isBeginning: false,
    isEnd: false,
    isCommunityWelcome: false,
  })
  return (
    <DiscoSection
      className={classNames(classes.paperContainer, props.className)}
      padding={2.5}
    >
      {showHeader && (
        <div className={classes.header} style={{ width: "100%" }}>
          {title ? (
            <DiscoText variant={"body-lg-600"}>{title}</DiscoText>
          ) : (
            showTitle && <DiscoTextSkeleton variant={"body-lg-600"} width={"60%"} />
          )}

          <div
            className={classNames(classes.actions, props.classes?.actions)}
            style={{ justifyContent: "flex-end" }}
          >
            <DiscoIconButtonSkeleton width={24} height={24} />
            <DiscoIconButtonSkeleton width={24} height={24} />
          </div>
        </div>
      )}

      <div
        style={{
          display: "grid",
          gap: theme.spacing(2),
          width: "100%",
          gridTemplateColumns: `repeat(${slidesPerView}, 1fr)`,
        }}
      >
        {range(Math.round(slidesPerView)).map((i) => (
          <Fragment key={i}>{item}</Fragment>
        ))}
      </div>
    </DiscoSection>
  )
}

export default DiscoCarousel
